import { ReactElement, useMemo, useState } from 'react';
import { Card, Typography } from '@vartanainc/design-system';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { useQuery } from '@apollo/client';

import AutoLoad from '../../../../components/AutoLoad';
import './ShareLink.scss';
import { GET_CUSTOMER_NAME_BY_NUMBER } from '../../../../graphql/queries/customer';
import SendCheckoutLinkForm from '../../../Customers/SendCheckoutLinkForm';
import { WidgetContext } from '../../../../context/WidgetContext';
import CommonWidgetV2 from '../../../CommonWidgetV2/commonWidget';
import TrackRecipients from '../Summary/TrackRecipients';
import { CARD_VARIANTS } from '../../../../constants/common.constants';
import { CardVariantType } from '../../../../utils/commonInterfaces';
import { titleize } from '../../../../utils/helpers';

export default function ShareLink(): ReactElement {
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const companyNumber = useQueryParams.get('customerNumber');

  const [formFocusSwitch, setFormFocusSwitch] = useState(false);

  const { data: customerData, loading: customerLoading } = useQuery(
    GET_CUSTOMER_NAME_BY_NUMBER,
    {
      variables: {
        number: companyNumber,
      },
    }
  );

  const widgetContext = useMemo(
    () => ({
      screen: 'track',
      selectedCompany: get(customerData, 'company'),
      product: get(customerData, 'company.seller.product', {}),
      setFormFocus: () => setFormFocusSwitch((e) => !e),
    }),
    [customerData]
  );

  const trackRecipients = useMemo(() => {
    const emailHistory = get(customerData, 'company.creditAppraisal.emailHistory', []);
    if (emailHistory?.length) {
      return (
        <Card
          variant={CARD_VARIANTS.variable as CardVariantType}
          content={(
            <TrackRecipients
              emailHistory={emailHistory?.map((email) => ({ id: uuidv4(), ...email }))}
            />
          )}
        />
      );
    }
    return null;
  }, [customerData]);

  return (
    <AutoLoad loading={customerLoading} containerClassName="absolute top-1/2 left-1/2">
      <div className="flex flex-col h-full pb-24 bg-vartana-steel-20 min-h-[calc(100vh-4rem)]">
        <div className="flex flex-col gap-2 px-8 border-b border-gray-200 min-h-[6.375rem] align-middle">
          <Typography variant="heading24" color="color-black-100" className="my-auto">
            {/* titleize is used to make each first letter capital for customer name */}
            {titleize(customerData?.company?.name)}
          </Typography>
        </div>
        <div className="flex flex-row p-10 gap-6 h-full justify-center">
          <div className="min-w-[43.813rem]">
            <SendCheckoutLinkForm
              appraisalNumber={companyNumber}
              formFocusSwitch={formFocusSwitch}
            />
          </div>

          <div className="flex flex-col gap-6 w-max">
            <div className="vd-customer-track-container relative bg-white shadow-lg rounded-lg  border-[1.5px] flex justify-center border-solid max-w-max">
              <WidgetContext.Provider value={widgetContext}>
                <CommonWidgetV2 />
              </WidgetContext.Provider>
            </div>
            {trackRecipients}
          </div>
        </div>
      </div>
    </AutoLoad>
  );
}
