import { useCallback, useMemo, useState, useReducer, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, startCase, lowerCase } from 'lodash';
import { toast } from 'react-toastify';
import { ButtonV2 } from '@vartanainc/design-system';
import { useLazyQuery } from '@apollo/client';
import { useDirectUploadReplaceDocumentBlobs } from '../../../utils/hooks';
import { ACTION, RESOURCE } from '../../../constants/permissions';
import { OrderShowContext } from '../../../context/OrderContext';
import UploadDocumentModal from '../../../components/Modals/UploadDocumentModal';
import CustomNotification from '../../../components/CustomNotification';
import attachmentIcon from '../../../assets/attachment.svg';
import { orderStates, DOC_TYPES } from '../../../constants/common.constants';
import { HasPermission } from '../../../components/HasPermission/HasPermission';
import { getUSDateTimeFormat } from '../../../utils/helpers';
import { ReactComponent as VerticalDivider } from '../../../assets/divider-line-gray.svg';
import { GET_ORDER_AGREEMENT_PREVIEW } from '../../../graphql/queries/order';
import Loader from '../../../components/Loader';

const Document = ({ document, showReplaceButton, onReplaceButtonClick }) => {
  const { selectedCustomer } = useContext(OrderShowContext);

  const replaceButton = useMemo(() => {
    if (showReplaceButton) {
      return (
        <HasPermission resource="order" action="update" customer={selectedCustomer}>
          <ButtonV2
            variant={{ type: 'text', typography: 'paragraph12' }}
            text="Replace"
            onClick={onReplaceButtonClick}
          />
        </HasPermission>
      );
    }
    return null;
  }, [showReplaceButton, selectedCustomer, onReplaceButtonClick]);

  return (
    <div key={document.id} className="flex p-4 border border-vartana-gray-30 rounded-md">
      <div className="flex-1 flex items-start space-x-1">
        <img src={attachmentIcon} alt="attachment" className="mt-0.5" />
        <div className="flex flex-col gap-[3px] space-y-0.5">
          <p className="vp-body text-vartana-black">
            {startCase(document.title || document.type)}
          </p>
          <p className="vp-p-small text-vartana-gray-50">
            {getUSDateTimeFormat(document.formattedCreatedAt)
              .replace('am', ' AM')
              .replace('pm', ' PM')}
          </p>
        </div>
      </div>
      <div className="flex-1 flex flex-row-reverse items-center divide-x divide-x-reverse divide-vartana-gray-30 gap-4">
        <HasPermission
          resource={RESOURCE.order}
          action={ACTION.download_documents}
          customer={selectedCustomer}
        >
          <a
            className="leading-none"
            rel="noreferrer"
            target="_blank"
            href={document.url}
          >
            <ButtonV2 variant={{ type: 'text', typography: 'paragraph12' }} text="View" />
          </a>
        </HasPermission>
        <HasPermission resource="order" action="update" customer={selectedCustomer}>
          {showReplaceButton && <VerticalDivider />}
        </HasPermission>
        {replaceButton}
      </div>
    </div>
  );
};

Document.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    createdAt: PropTypes.string,
    formattedCreatedAt: PropTypes.string,
  }).isRequired,
  showReplaceButton: PropTypes.bool,
  onReplaceButtonClick: PropTypes.func,
};

Document.defaultProps = {
  showReplaceButton: false,
  onReplaceButtonClick: () => {},
};

const documentInitialState = {
  id: null,
  type: '',
};

function documentReducer(state = documentInitialState, { type, payload }) {
  switch (type) {
  case 'update':
    return {
      ...state,
      id: get(payload, 'id', state.id),
      type: get(payload, 'type', state.type),
    };
  case 'reset':
    return { ...documentInitialState };
  default:
    return { ...state };
  }
}

function OrderDocuments({ orderMeta, documents }) {
  const { refetchOrderData } = useContext(OrderShowContext);

  const [directUploadReplaceDocumentBlobs] = useDirectUploadReplaceDocumentBlobs();

  const [showDocumentUploadModal, toggleDocumentUploadModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useReducer(
    documentReducer,
    documentInitialState
  );

  const [fetchOrderAgreement, { data: agreementPreview, loading: agreementLoading }] =
    useLazyQuery(GET_ORDER_AGREEMENT_PREVIEW, {
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first',
    });

  const orderStatus = get(orderMeta, 'status', 'pending');

  // Only fetch agreement doc if order is active & in checkout
  useEffect(() => {
    if (orderMeta?.number && orderStatus !== 'pending' && orderStatus !== 'canceled') {
      fetchOrderAgreement({
        variables: { number: orderMeta?.number },
      });
    }
  }, [fetchOrderAgreement, orderMeta?.number, orderStatus]);

  const openDocumentUploadModalWithType = useCallback(
    (document) => {
      setSelectedDocument({
        type: 'update',
        payload: document,
      });
      toggleDocumentUploadModal(true);
    },
    [setSelectedDocument, toggleDocumentUploadModal]
  );

  const agreementDocument = useMemo(() => {
    const hasCustomAgreement = documents.some(
      (doc) => doc.type === DOC_TYPES.CUSTOM_AGREEMENT
    );

    if (hasCustomAgreement) {
      return null;
    }

    if (agreementLoading) return <Loader containerClassName="py-4" />;

    const previewAgreement = get(agreementPreview, 'order.previewAgreement', {});

    let href = previewAgreement.url;
    let title = 'Final agreement';
    let fileName = `${get(orderMeta, 'number', 'Order')}-IPA.pdf`;
    if (previewAgreement.preview) {
      href = `data:application/pdf;base64,${previewAgreement.url}`;
      title = 'Preview agreement (Not signed)';
      fileName = `${get(orderMeta, 'number', 'Order')}-IPA-preview.pdf`;
    }

    if (orderStatus !== 'pending' && orderStatus !== 'canceled' && previewAgreement.url) {
      return (
        <div className="px-5 py-4 border border-vartana-gray-30 rounded-md">
          <div className="flex">
            <div className="flex-1 flex items-center space-x-2">
              <img src={attachmentIcon} alt="attachment" />
              <p className="vp-body text-vartana-black">{title}</p>
            </div>
            <div className="flex-1 flex flex-row-reverse items-center divide-x divide-x-reverse divide-vartana-gray-30">
              <a
                className="pl-4"
                rel="noreferrer"
                target="_blank"
                download={fileName}
                href={href}
              >
                <ButtonV2
                  variant={{ type: 'text', typography: 'paragraph12' }}
                  text="View"
                />
              </a>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }, [agreementLoading, agreementPreview, orderMeta, orderStatus, documents]);

  const orderDocuments = useMemo(() => {
    const userDocs = documents.map((document) => {
      const showReplaceButton =
        [orderStates.pending, orderStates.checkout].includes(orderStatus) &&
        document.type !== DOC_TYPES.CUSTOM_AGREEMENT;

      return (
        <Document
          key={document.id}
          document={document}
          showReplaceButton={showReplaceButton}
          onReplaceButtonClick={() =>
            openDocumentUploadModalWithType({
              id: document.id,
              type: document.type,
            })}
        />
      );
    });

    if (userDocs.length || agreementDocument) {
      return (
        <div className="grid grid-flow-row auto-rows-max space-y-4">
          {userDocs}
          {agreementDocument}
        </div>
      );
    }

    return null;
  }, [documents, agreementDocument, orderStatus, openDocumentUploadModalWithType]);

  return (
    <>
      <UploadDocumentModal
        open={showDocumentUploadModal}
        onClose={() => toggleDocumentUploadModal(false)}
        title={`Upload new ${lowerCase(selectedDocument.type)}`}
        onSubmit={async (values) => {
          await directUploadReplaceDocumentBlobs(
            [...values.documents],
            {
              documentType: selectedDocument.type,
              documentId: selectedDocument.id,
            },
            {
              id: get(orderMeta, 'id', ''),
              type: 'Order',
            }
          );
          await refetchOrderData();
          toggleDocumentUploadModal(false);
          toast.success(({ toastProps }) => {
            return (
              <CustomNotification
                type={toastProps.type}
                message="Order updated successfully! A new checkout link will be sent to the customer upon reviewing new term. Visit the “Track checkout” tab for updates on the order."
              />
            );
          });
        }}
      />

      <div className="grid grid-flow-row auto-rows-max gap-y-4">{orderDocuments}</div>
    </>
  );
}

OrderDocuments.propTypes = {
  orderMeta: PropTypes.shape({
    id: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  documents: PropTypes.arrayOf(Document.propTypes.document),
};

OrderDocuments.defaultProps = {
  documents: [],
};

export default OrderDocuments;
