import { ReactElement, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { Button, InputFieldMoneyFormat, Typography } from '@vartanainc/design-system';
import { useMutation } from '@apollo/client';

import { CREATE_CREDIT_APPRAISAL_CHANGE_REQUEST_V2 } from '../../graphql/queries/customer';
import { ReactComponent as ThreeDotsPlaceholder } from '../../assets/three-dots.svg';
import MoneyInputField from '../../designSystem/MoneyInput/MoneyInputField';
import CustomDropdown from '../../designSystem/FormikDropdownInput/FormikDropdownInput';
import {
  OrderContractLengthProps,
  OrderCustomerType,
  OrderFormValues,
  ProposalErrorsType,
} from './OrderTypes';
import { parseFloatWithTrailingZeros } from './order.utils';
import SubsidyField from '../../designSystem/CompactFields/SubsidyField';
import TermsDropdown from '../../designSystem/CompactFields/TermsDropdown';
import MultiFileUpload from '../../components/MultiFileUpload';
import {
  orderType,
  orderTypeMapping,
  paymentFrequencyLabels,
  proposalFields,
  oneWholeTwoDecimal,
  TOAST_SUCCESS_STATUS,
  CHANGE_REQUEST_SUCCESS_MESSAGE,
  paymentTermLabels,
  ORDER_PAY_IN_FULL_VALUE,
  SPIFF_MODE,
  CONFIRMATION_SCREEN_VARIANTS,
} from '../../constants/common.constants';
import { getPageUrl, showToast } from '../../utils/helpers';
import {
  MAX_ALLOWED_TCV,
  orderScreenRegex,
  TCV_BELOW_APPROVED_ERROR,
} from './order.constants';
import PaymentOptionSelect from '../../components/PaymentOptionSelect/PaymentOptionSelect';

const CRM_ORDER_PATH = '/forms/order';
const MODIFICATION_REASON = 'Change request from Order form';

interface ProposalFormProps {
  financeInfoLoading: boolean;
  proposalErrors: ProposalErrorsType[];
  formValues: OrderFormValues;
  index: number;
  deferPaymentTerms: number[];
  directPaymentTerms: number[];
  installmentPaymentTerms: number[];
  selectedCustomer: OrderCustomerType;
  customerNumber: string;
  paymentOptions: { label: string; value: string; isDisabled: boolean }[];
  contractLengthOptions: OrderContractLengthProps[];
  filteredPaymentFrequencies: string[];
  hideTermDropdown: boolean;
  setHideTermDropdown: (hideTermDropdown: boolean) => void;
  setFieldValue: (field: string, value) => void;
  spiffMode: string;
  subsidyAllowedOnProduct: boolean;
  setCreatingChangeRequest: (creatingChangeRequest: boolean) => void;
  currencySymbol: string;
  isSyndicated: boolean;
  isDemoVendor: boolean;
}

const ProposalForm = ({
  financeInfoLoading,
  proposalErrors,
  formValues,
  index,
  deferPaymentTerms,
  directPaymentTerms,
  installmentPaymentTerms,
  selectedCustomer,
  customerNumber,
  paymentOptions,
  contractLengthOptions,
  filteredPaymentFrequencies,
  hideTermDropdown,
  setHideTermDropdown,
  setFieldValue,
  spiffMode,
  subsidyAllowedOnProduct,
  setCreatingChangeRequest,
  currencySymbol,
  isSyndicated,
  isDemoVendor,
}: ProposalFormProps): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const pricingEngineError = get(proposalErrors, '[index].pricing_engine', '');
  const currentProposal = `proposals.${index}`;
  const currentProposalErrors = proposalErrors[index];
  const hasProposalErrors = proposalErrors?.length > 0;
  const showPricingEngineError =
    !financeInfoLoading && hasProposalErrors && !!pricingEngineError;

  const showTCVErrors =
    !showPricingEngineError &&
    hasProposalErrors &&
    !pricingEngineError &&
    !!currentProposalErrors?.amount &&
    formValues.amount;

  const hasPricingEngineError = hasProposalErrors && !pricingEngineError;
  const showTCVMoneyInputFieldError =
    showPricingEngineError || (hasPricingEngineError && !!currentProposalErrors?.amount);

  const netTermOptions = useMemo(() => {
    let selectedTerms;
    if (formValues.orderType === orderType.pay_in_full) selectedTerms = deferPaymentTerms;
    else if (formValues.orderType === orderType.full_payment)
      selectedTerms = directPaymentTerms;
    else selectedTerms = installmentPaymentTerms;
    let netTerms = selectedTerms?.map((netTerm) => ({
      value: netTerm,
      label: netTerm === 0 ? paymentTermLabels[0] : `Net-${netTerm}`,
    }));

    const shouldFilterNetZero =
      selectedCustomer?.invoiceEnabled &&
      formValues.orderType !== orderType.full_payment &&
      !isDemoVendor;
    if (shouldFilterNetZero) netTerms = netTerms.filter((netTerm) => netTerm.value !== 0);
    return netTerms;
  }, [
    formValues.orderType,
    deferPaymentTerms,
    directPaymentTerms,
    installmentPaymentTerms,
    selectedCustomer?.invoiceEnabled,
    isDemoVendor,
  ]);

  const DOLLAR_OPTION = { label: currencySymbol, value: '$' };
  const PERCENTAGE_OPTION = { label: '%', value: '%' };

  const shouldDisableTermField = contractLengthOptions?.length === 1 && isSyndicated;
  const shouldDisableNetTerms = netTermOptions?.length === 1 && isSyndicated;
  const shouldDisableBillingFrequency =
    filteredPaymentFrequencies?.length === 1 && isSyndicated;

  useEffect(() => {
    if (
      shouldDisableTermField &&
      formValues.contractLength !== contractLengthOptions[0].value
    ) {
      setFieldValue(`${currentProposal}.contractLength`, contractLengthOptions[0].value);
    }
    if (shouldDisableNetTerms && formValues.paymentTerm !== netTermOptions[0].value) {
      setFieldValue(`${currentProposal}.paymentTerm`, netTermOptions[0].value);
    }
    if (
      shouldDisableBillingFrequency &&
      formValues.paymentFrequency !== filteredPaymentFrequencies[0]
    ) {
      setFieldValue(`${currentProposal}.paymentFrequency`, filteredPaymentFrequencies[0]);
    }
  }, [
    contractLengthOptions,
    currentProposal,
    filteredPaymentFrequencies,
    formValues.contractLength,
    formValues.paymentFrequency,
    formValues.paymentTerm,
    netTermOptions,
    setFieldValue,
    shouldDisableBillingFrequency,
    shouldDisableNetTerms,
    shouldDisableTermField,
  ]);

  const [createCreditAppraisalChangeRequest] = useMutation(
    CREATE_CREDIT_APPRAISAL_CHANGE_REQUEST_V2
  );

  const handleSubsidyUnitChange = (): void => {
    let subsidy = parseFloatWithTrailingZeros(formValues.subsidy);
    const { isDollar, amount: totalContractFormValue } = formValues;
    const totalContractValue = parseFloatWithTrailingZeros(totalContractFormValue);

    if (totalContractValue) {
      // if converting to percentage then convert subsidy value to percentage as well
      if (isDollar) {
        subsidy = parseFloatWithTrailingZeros(
          ((subsidy / totalContractValue) * 100).toFixed(2)
        );
      }
      // if converting to value then convert subsidy from percentage back to value
      else {
        subsidy = parseFloatWithTrailingZeros(
          ((subsidy / 100) * totalContractValue).toFixed(2)
        );
      }
    }

    setFieldValue(`${currentProposal}.subsidy`, Math.round(subsidy));
    setFieldValue(
      `${currentProposal}.maxSubsidyAllowed`,
      formValues.isDollar ? formValues.maxSubsidyPercentage : formValues.dollarMaxSubsidy
    );
    setFieldValue(`${currentProposal}.isDollar`, !formValues.isDollar);
  };

  const handleCreateChangeRequest = async (): Promise<void> => {
    setCreatingChangeRequest(true);
    const creditChangeRequestPayload = {
      totalContractAmount: formValues.amount,
      billingFrequency:
        formValues.orderType === orderType.pay_in_full
          ? ORDER_PAY_IN_FULL_VALUE
          : formValues.paymentFrequency,
      modificationReason: MODIFICATION_REASON,
      paymentTerm: formValues.paymentTerm,
      term:
        formValues.orderType === orderType.full_payment
          ? 0
          : parseFloat(formValues.contractLength as unknown as string),
      companyNumber: customerNumber,
      paymentOption: formValues.orderType,
    };
    const responseData = await createCreditAppraisalChangeRequest({
      variables: creditChangeRequestPayload,
    });
    setCreatingChangeRequest(false);
    const changeRequestCreated =
      !!responseData?.data?.createCreditAppraisalChangeRequestV2?.id;
    if (changeRequestCreated) {
      if (location.pathname.includes(CRM_ORDER_PATH)) {
        const confirmationPage = getPageUrl({ page: 'confirmation' });
        navigate(confirmationPage, {
          state: {
            customerName: get(selectedCustomer, 'name', ''),
            variant: CONFIRMATION_SCREEN_VARIANTS.CHANGE_REQUEST,
          },
        });
      } else {
        const customerPage = getPageUrl({ page: 'customerSummary', customerNumber });
        navigate(customerPage);
        setTimeout(() => {
          showToast(TOAST_SUCCESS_STATUS, CHANGE_REQUEST_SUCCESS_MESSAGE);
        }, 500);
      }
    }
  };

  const showField = (fieldType): boolean => {
    return formValues.orderType
      ? orderTypeMapping[formValues.orderType]?.includes(fieldType)
      : false;
  };

  const getMaxSubsidy = (): string => {
    return `${formValues.isDollar ? currencySymbol : ''}${formValues.maxSubsidyAllowed}${
      !formValues.isDollar ? '%' : ''
    }`;
  };

  const applyMaxSubsidy = (): void => {
    setFieldValue(
      `${currentProposal}.subsidy`,
      formValues.maxSubsidyAllowed?.replace(orderScreenRegex.percentOrDollar, '')
    );
  };

  // this is a list of values that are used in the proposal form's JSX below, this help's with the JSX readability
  const showChangeRequestButton =
    currentProposalErrors?.amount?.includes('value is above') ||
    currentProposalErrors?.amount?.includes(TCV_BELOW_APPROVED_ERROR);
  const showTermError = hasProposalErrors && !!currentProposalErrors?.term;
  const termError = hasProposalErrors && currentProposalErrors?.term;
  const maxAllowedSubsidy = formValues.isDollar ? (formValues.amount as number) : 100;
  const showProposalFields = formValues.orderType && formValues.orderType !== 'direct';
  const billingOptions = filteredPaymentFrequencies.map((paymentFrequency) => ({
    label: paymentFrequencyLabels[paymentFrequency],
    value: paymentFrequency,
  }));

  return (
    <div className="grid grid-cols-1">
      <div className="space-y-1 mb-6">
        <Typography variant="paragraph12" color="color-gray-140">
          Order form and other documents
        </Typography>
        <MultiFileUpload
          uploadText="Click or drag PDF files to upload"
          name={`${currentProposal}.orderForm`}
          acceptMultipleFiles
        />
      </div>
      <div className="mb-6 flex flex-col gap-1">
        <Typography variant="paragraph12" color="color-gray-140">
          Payment options
        </Typography>
        <PaymentOptionSelect
          name={`proposals.${index}.orderType`}
          options={paymentOptions}
        />
      </div>
      {showProposalFields && (
        <div className="grid grid-cols-2 gap-4">
          <div
            className={`w-full ${
              // This will be used to highlight when there is an error from pricing engine
              // also when there is an actual error in amount field
              showTCVMoneyInputFieldError ? 'tcv-input' : ''
            }`}
          >
            <MoneyInputField
              placeholder="0"
              id={`${currentProposal}.amount`}
              name={`${currentProposal}.amount`}
              label="Total contract value"
              showFieldError={showTCVMoneyInputFieldError}
              maxAllowedValue={MAX_ALLOWED_TCV}
              minAllowedValue={1}
              suffix=""
              prefix={currencySymbol}
              applySetValueDebounce
            />

            {showTCVErrors && (
              <div className="flex flex-col mt-1">
                <Typography variant="paragraph10" color="color-red-160">
                  {currentProposalErrors?.amount}
                </Typography>
                {/* TODO - Nuyaan95, MuhammadAhmadEjaz - Create a separate component to render this button */}
                {showChangeRequestButton ? (
                  <button
                    className="flex items-start w-44 "
                    onClick={handleCreateChangeRequest}
                    type="button"
                  >
                    <Typography variant="paragraph10" color="color-blue-120">
                      Click here to get this limit approved
                    </Typography>
                  </button>
                ) : null}
              </div>
            )}
          </div>
          {showField(proposalFields.contractLength) ? (
            <TermsDropdown
              id={`${currentProposal}.contractLength`}
              name={`${currentProposal}.contractLength`}
              label="Contract length"
              placeholder="Type or select an option"
              options={contractLengthOptions as unknown as OrderContractLengthProps[]}
              showError={showTermError}
              errorMsg={termError || ''}
              closeDropdown={hideTermDropdown}
              handleCloseDropdown={setHideTermDropdown}
              resetOptionsOnBlur
              disabled={shouldDisableTermField}
            />
          ) : null}
          {showField(proposalFields.paymentFrequency) ? (
            <CustomDropdown
              id={`${currentProposal}.paymentFrequency`}
              name={`${currentProposal}.paymentFrequency`}
              className="flex-1"
              label="Billing frequency"
              placeholder="Select option"
              options={billingOptions}
              autoFocus={false}
              openMenuOnFocus={false}
              preFillFirstOption={false}
              isLoading={false}
              disabled={shouldDisableBillingFrequency}
            />
          ) : null}
          {showField(proposalFields.paymentTerm) ? (
            <CustomDropdown
              id={`${currentProposal}.paymentTerm`}
              name={`${currentProposal}.paymentTerm`}
              className="flex-1"
              label="Net terms"
              placeholder="Select option"
              disabled={shouldDisableNetTerms}
              options={
                netTermOptions &&
                netTermOptions.map((paymentTerm) => {
                  return {
                    label: paymentTerm.label,
                    value: paymentTerm.value,
                  };
                })
              }
              autoFocus={false}
              openMenuOnFocus={false}
              preFillFirstOption={false}
              isLoading={false}
            />
          ) : null}
          {showField(proposalFields.subsidy) &&
          subsidyAllowedOnProduct &&
          formValues.vartanaFinancing ? (
            <div className="gap-1 flex flex-col w-full flex-1">
              <div className="flex justify-between items-center">
                <Typography
                  variant="paragraph12"
                  color={
                    hasProposalErrors && !!currentProposalErrors?.subsidy
                      ? 'color-red-160'
                      : 'color-gray-140'
                  }
                >
                  Subsidy
                </Typography>

                <div className=" flex items-center gap-1">
                  <Typography variant="paragraph10" color="color-gray-140">
                    Max subsidy
                  </Typography>
                  {get(formValues, 'maxSubsidyAllowed', false) ? (
                    <div className="flex items-center gap-1.5">
                      <Typography
                        variant="paragraph10"
                        color="color-blue-180"
                        className="subsidy-bold-text"
                      >
                        {getMaxSubsidy()}
                      </Typography>
                      <Button
                        backgroundColor="tertiary"
                        onClick={applyMaxSubsidy}
                        className="!border-0"
                        size="xx-small"
                        type="button"
                      >
                        Apply
                      </Button>
                    </div>
                  ) : (
                    <ThreeDotsPlaceholder />
                  )}
                </div>
              </div>
              <SubsidyField
                label=""
                id={`${currentProposal}.subsidy`}
                name={`${currentProposal}.subsidy`}
                onUnitChange={() => {
                  handleSubsidyUnitChange();
                }}
                onValueChange={(e) => {
                  setFieldValue(`${currentProposal}.subsidy`, e.value);
                }}
                customPrefix={formValues.isDollar ? currencySymbol : ''}
                placeholder="0"
                isCompact={false}
                minAllowedValue={0}
                maxAllowedValue={maxAllowedSubsidy}
                decimalScale={2}
                defaultSelectValue={
                  formValues.isDollar ? DOLLAR_OPTION : PERCENTAGE_OPTION
                }
                errorMessage={
                  hasProposalErrors && !!currentProposalErrors?.subsidy
                    ? currentProposalErrors?.subsidy
                    : ''
                }
                fixedDecimalScale={false}
                showError={hasProposalErrors && !!currentProposalErrors?.subsidy}
                value={get(formValues, 'subsidy', 0) as unknown as undefined}
                selectContainerClassname="custom-select-container"
                options={[DOLLAR_OPTION, PERCENTAGE_OPTION]}
              />
            </div>
          ) : null}
          {spiffMode !== SPIFF_MODE.none && (
            <div className="flex-1">
              <InputFieldMoneyFormat
                id={`${currentProposal}.spiffRate`}
                name={`${currentProposal}.spiffRate`}
                label="SPIFF"
                maxAllowedValue={oneWholeTwoDecimal}
                disabled={spiffMode === SPIFF_MODE.fixed}
                placeholder="0"
                showError={hasProposalErrors && !!currentProposalErrors?.spiff}
                helperText={(hasProposalErrors && currentProposalErrors?.spiff) || ''}
                value={get(formValues, 'spiffRate', 0) as unknown as undefined}
                onChange={(e) => {
                  setFieldValue(`${currentProposal}.spiffRate`, e.value);
                }}
                decimalScale={2}
                customSuffix="%"
                fullWidth
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default ProposalForm;
