/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';

import PropTypes from 'prop-types';

import * as yup from 'yup';
import { Button } from '@vartanainc/design-system';
import { useLocation, useNavigate } from 'react-router-dom';

import { omit, get, mapKeys, isEmpty, capitalize } from 'lodash';

import { useMutation } from '@apollo/client';

import { useBeforeunload } from 'react-beforeunload';
import { useDirectUploadFiles } from '../../../../utils/hooks';
import FormInput from '../../../../components/FormInput';
import ButtonToggle from '../../../../components/ButtonToggle';
import AutoLoad from '../../../../components/AutoLoad';
import MultiFileUpload from '../../../../components/MultiFileUpload';
import FormReadOnlyInput from '../../../../components/FormReadOnlyInput';
import slashLine from '../../../../assets/slash_line.svg';
import { showErrorToast, showToast } from '../../../../utils/helpers';
import MultiSelectButton from '../../../../components/MultiSelectButton';
import { CREATE_CREDIT_APPRAISAL_CHANGE_REQUEST } from '../../../../graphql/queries/creditAppraisalChangeRequest';
import {
  commonRegex,
  FIELD_INVALID_MESSAGES,
  requiredPhoneFieldValidation,
  THRESHOLD_AMOUNT,
  THRESHOLD_FORMATTED,
} from '../../../../constants/common.constants';
import TextInputField from '../../../../designSystem/TextInput/TextInputField';
import PatternInput from '../../../../designSystem/PatternInput/PatternInput';
import TextAreaInput from '../../../../designSystem/TextAreaInput/TextAreaInput';
import MoneyInputField from '../../../../designSystem/MoneyInput/MoneyInputField';

const baseSchema = {
  message: yup.string().required('This field is required'),
  increaseThresholdAmount: yup.boolean().oneOf([true, false], 'This field is required'),
  increaseTerm: yup.boolean().oneOf([true, false], 'This field is required'),
  amount: yup
    .number()
    .positive()
    .nullable()
    .required('This field is required')
    .when('increaseThresholdAmount', {
      is: true,
      then: yup
        .number()
        .min(THRESHOLD_AMOUNT + 0.01, `Enter an amount above ${THRESHOLD_FORMATTED}.`),
      otherwise: yup
        .number()
        .max(THRESHOLD_AMOUNT, `Enter an amount upto ${THRESHOLD_FORMATTED}.`),
    }),
  term: yup
    .number()
    .min(12, 'Please input a term between 12 and 60 months.')
    .max(60, 'Please input a term between 12 and 60 months.')
    .integer('Please input an integer.')
    .nullable(),
  billingFrequencies: yup.array().nullable(),
  paymentTerms: yup.array().nullable(),
};

const uploadDocumentsSchema = {
  financialDocuments: yup.array().required().min(1, 'Financial Document is required'),
};

const sendCheckoutLinkSchema = {
  firstName: yup
    .string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.firstName),
  lastName: yup
    .string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.lastName),
  email: yup
    .string()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('Email is required'),
  phone: requiredPhoneFieldValidation,
  companyMessage: yup.string().required('Please include a message'),
};

function RequestHigherLimitForm({
  companyName,
  availableFrequencies,
  availablePaymentTerms,
  conditionalToPg,
  creditAssessmentType,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = location.search;
  const useQueryParams = new URLSearchParams(queryParams);
  const customerNumber = useQueryParams.get('customerNumber') || '';

  const [canSafelyExit, setCanSafelyExit] = useState(true);
  const [formSchema, setFormSchema] = useState(baseSchema);
  const [directUploadFiles] = useDirectUploadFiles();
  const [docsRequiredBtn, setDocsRequiredBtn] = useState([]);

  const [createCreditAppraisalChangeRequest] = useMutation(
    CREATE_CREDIT_APPRAISAL_CHANGE_REQUEST
  );

  useEffect(() => {
    if (conditionalToPg) {
      setDocsRequiredBtn([{ label: 'Request from customer', value: false }]);
    } else {
      setDocsRequiredBtn([
        { label: 'Upload documents', value: true },
        { label: 'Request from customer', value: false },
      ]);
    }
  }, [conditionalToPg]);

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      try {
        const {
          financialDocuments,
          uploadDocuments,
          increaseThresholdAmount,
          increaseTerm,
          amount,
          term,
          billingFrequencies,
          paymentTerms,
        } = values;

        let cleanedValues = omit(values, [
          'uploadDocuments',
          'financialDocuments',
          'companyName',
        ]);

        if (term) cleanedValues = { ...cleanedValues, term: +term };

        if (!increaseTerm) {
          cleanedValues = omit(cleanedValues, ['term']);
        }

        if (!increaseThresholdAmount || uploadDocuments) {
          cleanedValues = omit(cleanedValues, [
            'firstName',
            'lastName',
            'email',
            'phone',
            'companyMessage',
          ]);
        }

        if (!amount) {
          cleanedValues = {
            ...cleanedValues,
            amount: 0,
          };
        }

        if (!term) {
          cleanedValues = {
            ...cleanedValues,
            term: 0,
          };
        }

        if (!billingFrequencies)
          cleanedValues = omit(cleanedValues, 'billingFrequencies');
        if (!paymentTerms) cleanedValues = omit(cleanedValues, 'paymentTerms');

        const responseData = await createCreditAppraisalChangeRequest({
          variables: {
            ...cleanedValues,
          },
        });

        const newCreditAppraisalId = get(
          responseData,
          'data.createCreditAppraisalChangeRequest.id',
          ''
        );
        const errors = get(responseData, 'errors', {});

        let successMessage =
          'Your request for terms modification is logged. Upon review, new terms will be generated.';
        const errorsCleaned = mapKeys(errors, (_, key) => {
          if (key === 'creditAppraisalChangeRequestTerm') return 'term';
          if (key === 'creditAppraisalChangeRequestAmount') return 'amount';
          if (key === 'creditAppraisalChangeRequestBillingFrequencies')
            return 'billingFrequencies';
          if (key === 'creditAppraisalChangeRequestPaymentTerms') return 'paymentTerms';
          return key;
        });

        if (Object.keys(errorsCleaned).length) {
          setErrors(errorsCleaned);
        } else {
          if (uploadDocuments) {
            await directUploadFiles(
              [...financialDocuments],
              {
                documentType: 'financial',
              },
              {
                id: newCreditAppraisalId,
                type: creditAssessmentType,
              }
            );
          }

          if (increaseThresholdAmount && !uploadDocuments) {
            // send application
            successMessage =
              'New terms will be generated upon receiving financial documents. Visit “Track application” tab for updates.';
          }

          setCanSafelyExit(true);

          setTimeout(() => {
            showToast('success', successMessage);
            navigate(`/forms/help${queryParams}&help=true`);
          }, 0);
        }
      } catch (e) {
        showErrorToast();
      }
    },
    [
      navigate,
      setCanSafelyExit,
      createCreditAppraisalChangeRequest,
      directUploadFiles,
      queryParams,
      creditAssessmentType,
    ]
  );

  const formikBag = useFormik({
    initialValues: {
      companyNumber: customerNumber,
      companyName,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyMessage: '',
      increaseThresholdAmount: null,
      increaseTerm: null,
      uploadDocuments: false,
      message: '',
      amount: '',
      term: '',
      billingFrequencies: [],
      paymentTerms: [],
      financialDocuments: undefined,
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit,
  });

  const {
    isSubmitting,
    isValid,
    dirty,
    values: { increaseThresholdAmount, increaseTerm, uploadDocuments },
    touched,
    handleSubmit,
  } = formikBag;

  const disabled = isSubmitting || !(isValid && dirty);

  useBeforeunload((event) => {
    if (!canSafelyExit) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    let newSchema = { ...baseSchema };

    if (increaseThresholdAmount) {
      if (uploadDocuments) newSchema = Object.assign(newSchema, uploadDocumentsSchema);
      else newSchema = Object.assign(newSchema, sendCheckoutLinkSchema);
    }

    setFormSchema(
      yup.object().shape({
        ...newSchema,
      })
    );
  }, [uploadDocuments, increaseThresholdAmount, setFormSchema]);

  useEffect(() => {
    setCanSafelyExit(!dirty || Object.keys(touched).length === 0);
  }, [touched, dirty]);

  const uploadDocumentsForm = useMemo(() => {
    if (uploadDocuments) {
      return (
        <>
          <MultiFileUpload
            name="financialDocuments"
            label="Financial statements (2 years)"
            acceptMultipleFiles
          />
        </>
      );
    }
    return (
      <>
        <FormReadOnlyInput
          name="companyName"
          label="Company Name"
          className="flex-1"
          disabled
        />

        <div className="flex flex-row space-x-4">
          <TextInputField name="firstName" label="Legal first name" />

          <TextInputField name="lastName" label="Legal last name" />
        </div>

        <div className="flex flex-row space-x-4">
          <TextInputField name="email" label="Signer's email" />

          <PatternInput
            name="phone"
            label="Phone"
            type="text"
            disableFullStoryRecording
            mask="_"
          />
        </div>

        <div className="flex flex-row">
          <TextAreaInput
            name="companyMessage"
            label="Include a message"
            placeholder="Hello..."
          />
        </div>
      </>
    );
  }, [uploadDocuments]);

  const additionalTermOptions = useMemo(() => {
    if (isEmpty(availableFrequencies) && isEmpty(availablePaymentTerms)) return null;

    const formattedPaymentTerms = availablePaymentTerms.map((term) =>
      term ? [`Net-${term}`, term] : ['Upon receipt', term]
    );

    return (
      <div className="space-y-6 pb-8 mb-2">
        <h1 className="vp-card-title text-vartana-black mt-8">Additional term options</h1>
        {availableFrequencies.length ? (
          <div>
            <MultiSelectButton
              name="billingFrequencies"
              label="Add frequencies"
              options={availableFrequencies.map((frequency) => ({
                label: capitalize(frequency),
                value: frequency,
              }))}
            />
          </div>
        ) : null}
        {availablePaymentTerms.length ? (
          <div>
            <MultiSelectButton
              name="paymentTerms"
              label="Add payment terms"
              options={formattedPaymentTerms.map(([label, value]) => ({
                label,
                value,
              }))}
            />
          </div>
        ) : null}
      </div>
    );
  }, [availableFrequencies, availablePaymentTerms]);

  return (
    <>
      <FormikProvider value={formikBag}>
        <form onSubmit={handleSubmit}>
          <fieldset disabled={isSubmitting}>
            <div className="grid grid-cols-1 divide-sf-vartana-gray-30">
              <div className="space-y-6 pb-8">
                <FormInput className="hidden" name="companyNumber" type="hidden" />
                <div>
                  <ButtonToggle
                    name="increaseThresholdAmount"
                    label="Amount needed (across all plans):"
                    buttonProps={[
                      {
                        label: `Under ${THRESHOLD_FORMATTED}`,
                        value: false,
                      },
                      { label: `Over ${THRESHOLD_FORMATTED}`, value: true },
                    ]}
                  />
                </div>
                <div className="pt-2">
                  <ButtonToggle
                    name="increaseTerm"
                    label="Maximum term (across all plans):"
                    buttonProps={[
                      {
                        label: 'Within approved options',
                        value: false,
                      },
                      { label: 'Beyond approved options', value: true },
                    ]}
                  />
                </div>
                <div className="flex flex-row space-x-4 pt-2">
                  <MoneyInputField
                    id="amount"
                    name="amount"
                    label="Specific amount"
                    placeholder={0}
                    // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get currency from product/credit appraisal
                    prefix="$"
                  />
                  {increaseTerm ? (
                    <>
                      <img className="self-center" alt="slash" src={slashLine} />
                      <TextInputField
                        id="term"
                        name="term"
                        type="number"
                        label="Specific term (optional)"
                      />
                    </>
                  ) : null}
                </div>
                <div className="flex flex-row">
                  <TextAreaInput
                    name="message"
                    label="Supporting details"
                    tooltipElement={(
                      <p>
                        Include specifics about the customer or <br />
                        potential order. For example, “Customer <br />
                        is renewing 40 units and adding 10 units <br />
                        to support future growth”
                      </p>
                    )}
                  />
                </div>

                {increaseThresholdAmount ? (
                  <>
                    <div className="space-y-4">
                      <h1 className="vp-card-title text-vartana-black">
                        Documents required
                      </h1>
                      <div className="text-sm leading-5 text-vartana-gray-60 space-y-2">
                        <p>
                          Additional documents are needed for requests over{' '}
                          {THRESHOLD_FORMATTED}:
                        </p>
                        <ul className="list-disc ml-8">
                          <li>Last 2 years of financial statements</li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      <ButtonToggle
                        name="uploadDocuments"
                        label="Select a way to submit documents:"
                        buttonProps={docsRequiredBtn}
                      />
                    </div>

                    {uploadDocumentsForm}
                  </>
                ) : null}
                {additionalTermOptions}
              </div>
            </div>

            <AutoLoad loading={isSubmitting}>
              <div className="flex flex-row-reverse items-center space-x-reverse space-x-8">
                <div>
                  <Button type="submit" size="x-large" disabled={disabled}>
                    Submit
                  </Button>
                </div>
                <div>
                  <button onClick={() => window.close()}>
                    <p className="vp-text-link text-vartana-blue-60">Cancel</p>
                  </button>
                </div>
              </div>
            </AutoLoad>
          </fieldset>
        </form>
      </FormikProvider>
    </>
  );
}

RequestHigherLimitForm.propTypes = {
  companyName: PropTypes.string.isRequired,
  availableFrequencies: PropTypes.arrayOf(PropTypes.string),
  availablePaymentTerms: PropTypes.arrayOf(PropTypes.number),
  conditionalToPg: PropTypes.bool,
  creditAssessmentType: PropTypes.string,
};

RequestHigherLimitForm.defaultProps = {
  availableFrequencies: [],
  availablePaymentTerms: [],
  conditionalToPg: false,
  creditAssessmentType: 'CreditAppraisal',
};

export default RequestHigherLimitForm;
