import { StatusPill, Typography, Card, AlertMessage } from '@vartanainc/design-system';
import { Ref, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { sessionVar } from '../../../../graphql/cache';
import { APP_NAMES } from '../../../CommonWidgetV2/commonWidget.constants';
import { PROGEN_CTA } from '../../../../constants/common.constants';
import { progenDefaultTexts } from '../../../../constants/progenTexts';
import { WidgetMetaContext } from '../../../../context/WidgetMetaContext/WidgetMetaContext';
import { CalculatorResponseProps } from '../widgetV2.constants';
import {
  showErrorToast,
  isZero,
  formatCurrency,
  removeTrailingZeros,
  getPageUrl,
} from '../../../../utils/helpers';
import TabsSummary from '../TabsSummary/TabsSummary';
import {
  TertiaryActions,
  getPillStatus,
  isStatusPage,
  renderPgStatus,
  renderFeeTooltip,
  handlePageNavigate,
} from '../widgetUtils';
import Calculator from '../Calculator/Calculator';
import Loader from '../../../../components/Loader';
import LoaderMd from '../../../../components/LoaderMd/LoaderMd';
import '../../../CommonWidgetV2/CommonWidget.scss';
import { TCV_BELOW_APPROVED_ERROR } from '../../../Orders/order.constants';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import { CurrencyPill } from '../../../../components/Pill/CurrencyPill';

interface InstallmentTabProps {
  status: string;
  tertiaryActions: {
    label: string;
    type: string;
    slug: string;
  }[];
  setActive: (tab: string) => void;
  setSubPage: (page: string) => void;
  toggleModal: (action) => void;
  isCrm: boolean;
  companyNumber: string | undefined;
  crmOpportunityId: string | undefined;
  handleCancelCreditAppraisalChangeRequest: () => void;
  calculatorRef: Ref<unknown>;
  setCanDownloadProposal: (canDownloadProposal: boolean) => void;
  showSubPage: boolean;
  setShowSubPage: (showSubPage: boolean | ((prev: boolean) => boolean)) => void;
  setHasCalculatorError: (hasCalculatorError: boolean) => void;
  handleGetHigherLimit: () => void;
  creatingChangeRequest: boolean;
  appName: string | null;
  currency: string;
}

interface ErrorProps {
  message: string;
  subsidy: string;
  spiff: string;
}

const InstallmentTab = ({
  status,
  tertiaryActions,
  setActive,
  setSubPage,
  toggleModal,
  isCrm,
  companyNumber = '',
  crmOpportunityId = '',
  handleCancelCreditAppraisalChangeRequest,
  setCanDownloadProposal,
  calculatorRef,
  showSubPage,
  setShowSubPage,
  setHasCalculatorError,
  handleGetHigherLimit,
  creatingChangeRequest,
  appName,
  currency,
}: InstallmentTabProps): JSX.Element => {
  const [response, setResponse] = useState<CalculatorResponseProps>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorProps>();
  // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
  const [vendorFee, setVendorFee] = useState('$0');
  // subsidy error
  const [discountTooltip, setDiscountTooltip] = useState('');
  const [documentationFee, setDocumentationFee] = useState('');
  const [frequencyText, setFrequencyText] = useState('per month');

  const [spiffRate, setSpiffRate] = useState('');
  // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
  const [spiffAmount, setSpiffAmount] = useState('$0');
  const [showSpiff, setShowSpiff] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const metaContext = useContext(WidgetMetaContext);

  const sessionData = useReactiveVar(sessionVar);
  const showCurrencyPill = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled'
  );

  const resetTab = useCallback(() => {
    setResponse(undefined);
    setError(undefined);
    // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
    setVendorFee('$0');
    setDiscountTooltip('');
    setDocumentationFee('');
    setCanDownloadProposal(false);
  }, [setCanDownloadProposal]);

  useEffect(() => {
    if (!showSubPage) resetTab();
  }, [resetTab, showSubPage]);

  useEffect(() => {
    setCanDownloadProposal(false);
  }, [setCanDownloadProposal]);

  useEffect(() => {
    setActive('offerInstallment');
    setSubPage('');
    setShowSubPage(false);
  }, [setActive, setShowSubPage, setSubPage]);

  const toggleSubPage = (): void => {
    if (showSubPage) setSubPage('');
    setShowSubPage((prev) => !prev);
  };

  const handleTertiaryButtonClick = (action): void => {
    if (action.slug === PROGEN_CTA.modifyTerms) {
      if (isCrm) {
        const url = getPageUrl({
          customerNumber: companyNumber,
          otherParams: location.search,
          page: 'modifyTerms',
          expandedApp: true,
        });
        handlePageNavigate(url, appName === APP_NAMES.HUBSPOT, navigate);
      } else
        navigate(
          getPageUrl({
            customerNumber: companyNumber,
            page: 'modifyTerms',
          })
        );
    } else if (action.slug === PROGEN_CTA.creditInfo) {
      setSubPage('creditDetails');
      toggleSubPage();
    } else if (action.slug === PROGEN_CTA.submitInformation) {
      setSubPage('moreInformationDetails');
      toggleSubPage();
    } else if (action.slug === PROGEN_CTA.viewCreditDecision) {
      toggleModal(action);
    } else if (action.slug === PROGEN_CTA.learnMore) {
      toggleModal(action);
    } else if (action.slug === PROGEN_CTA.viewDecisionDetail) {
      toggleModal(action);
    } else if (action.slug === PROGEN_CTA.trackRequest) {
      setSubPage('trackRequestDetails');
      toggleSubPage();
    } else if (action.slug === PROGEN_CTA.cancelRequest) {
      handleCancelCreditAppraisalChangeRequest();
    } else {
      showErrorToast();
      reportError(`Unidentified action: ${JSON.stringify(action)}`);
    }
  };
  const handleValueChange = (resp): void => {
    // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
    const totalFormattedFee = formatCurrency(get(resp, 'total_formatted_fee', '$0'));
    const totalSpiffRate = get(resp, 'spiff_rate', '');
    const totalSpiffAmount = get(resp, 'spiff_amount', '$0');
    const discountRate = removeTrailingZeros(
      get(resp, 'formatted_discount_rate_tooltip', '')
    )
      .replace('(', '')
      .replace(')', ' or ')
      .toLowerCase();
    const docFee = removeTrailingZeros(
      get(resp, 'vendor_application_fee_tooltip', '')
    ).toLowerCase();
    setResponse(resp);
    setError(Object.keys(resp.errors || {}).length !== 0 ? resp.errors : null);
    // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
    setVendorFee(totalFormattedFee || '$0');
    setDiscountTooltip(discountRate || '$0 or 0% subsidy');
    setDocumentationFee(docFee || '$0 documentation fee');
    setHasCalculatorError(Object.keys(resp.errors || {}).length !== 0);
    setSpiffRate(totalSpiffRate);
    setSpiffAmount(totalSpiffAmount);
  };

  useEffect(() => {
    setShowSpiff(!isZero(spiffAmount));
  }, [spiffAmount]);

  const hasFee =
    get(response, 'vendor_application_fee_tooltip') ||
    get(response, 'formatted_discount_rate_tooltip') ||
    spiffRate === '0';

  const pgRequired = get(metaContext, 'meta.calculator.pgRequired', false);

  const renderSpiffAmount = showSpiff && get(response, 'spiff_amount', false);

  const handleFrequencyChange = (frequency): void => {
    const { value } = frequency;
    setFrequencyText(progenDefaultTexts.frequency_labels[value] || '');
  };
  return (
    <>
      {isStatusPage(status) || showSubPage ? (
        <TabsSummary
          status={status}
          onBackClick={toggleSubPage}
          onButtonClick={handleTertiaryButtonClick}
          isCrm={isCrm}
          companyNumber={companyNumber}
          appName={appName}
        />
      ) : (
        <div className="flex flex-col w-[25.125rem] gap-4 h-[19.125rem]">
          <div className="flex flex-col gap-3">
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row gap-2">
                <StatusPill status={getPillStatus(status)} text={status} />
                {showCurrencyPill && <CurrencyPill compactPill currency={currency} />}
              </div>
              <TertiaryActions
                actions={tertiaryActions}
                onActionClick={handleTertiaryButtonClick}
              />
            </div>

            <Card
              padding="compact"
              parentContainerClassName="progen-card"
              content={(
                <Calculator
                  onValueChange={handleValueChange}
                  setLoading={setIsLoading}
                  calculatorRef={calculatorRef}
                  isInstallment
                  setCanDownloadProposal={setCanDownloadProposal}
                  onFrequencyChange={handleFrequencyChange}
                  setError={setError}
                  error={error}
                />
              )}
            />
          </div>
          <div className={`${error ? '' : 'flex flex-col justify-center h-full'}`}>
            {isLoading ? (
              <Loader containerClassName="flex items-center justify-center h-full" />
            ) : (
              <>
                {error ? (
                  <div className="text-left">
                    {get(error, 'message') || get(error, 'amount', '') ? (
                      <>
                        <AlertMessage
                          content={removeTrailingZeros(
                            get(error, 'message', '') || get(error, 'amount', '')
                          )}
                          type="error"
                          hyperLinkContent={
                            (get(error, 'amount', '').includes('value is above') ||
                              get(error, 'amount', '').includes(
                                TCV_BELOW_APPROVED_ERROR
                              )) &&
                            !get(error, 'message', '').includes('No rates')
                              ? 'Click here to get this limit approved'
                              : ''
                          }
                          onHyperLinkClick={handleGetHigherLimit}
                          hyperLinkDisabled={creatingChangeRequest}
                        />
                        {creatingChangeRequest && (
                          <div className="w-full flex justify-center mt-5">
                            <LoaderMd />
                          </div>
                        )}
                      </>
                    ) : null}
                    {get(error, 'subsidy', false) ? (
                      <div className={get(error, 'subsidy', false) ? 'mt-2' : ''}>
                        <AlertMessage
                          content={get(error, 'subsidy').replace('.00', '')}
                          type="error"
                        />
                      </div>
                    ) : null}
                    {get(error, 'term', false) ? (
                      <div className={get(error, 'term', false) ? 'mt-2' : ''}>
                        <AlertMessage content={get(error, 'term', '')} type="error" />
                      </div>
                    ) : null}
                    {get(error, 'spiff', false) ? (
                      <div className={get(error, 'spiff', false) ? 'mt-2' : ''}>
                        <AlertMessage
                          content={get(error, 'spiff').replace('.00', '')}
                          type="error"
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="flex flex-col gap-2">
                    <div
                      className={`w-full flex flex-row gap-4 self-stretch ${
                        showSpiff || pgRequired ? '' : 'items-center'
                      }`}
                    >
                      <div className="flex flex-col items-center w-[calc(100%-2.063rem)]">
                        <Typography
                          variant="paragraph10"
                          color={
                            get(response, 'payment')
                              ? 'color-black-100'
                              : 'color-gray-110'
                          }
                        >
                          Buyer pays
                        </Typography>

                        {get(response, 'payment') ? (
                          <div className="my-[0.344rem]">
                            <Typography variant="heading24" color="color-blue-180" bold>
                              {get(response, 'payment', '').split('/')[0]}
                            </Typography>
                          </div>
                        ) : (
                          <SvgIcon
                            className="transform rotate-90"
                            name="more_vert"
                            height="2rem"
                            width="2rem"
                            fill="#D9D9D9"
                          />
                        )}
                        <div className="flex gap-[0.12rem]">
                          <Typography
                            variant="paragraph10"
                            color={
                              get(response, 'payment')
                                ? 'color-black-100'
                                : 'color-gray-110'
                            }
                          >
                            {frequencyText}
                          </Typography>
                        </div>
                        {renderPgStatus(
                          get(metaContext, 'meta.calculator.pgRequired', false),
                          'mt-3.5',
                          get(response, 'remittance')
                            ? 'color-steel-140'
                            : 'color-gray-110'
                        )}
                      </div>
                      <div className="bg-gray-300 w-[0.125rem] h-[6.625rem]" />
                      <div className="flex flex-col items-center w-[calc(100%-2.063rem)]">
                        <Typography
                          variant="paragraph10"
                          color={
                            get(response, 'remittance')
                              ? 'color-black-100'
                              : 'color-gray-110'
                          }
                        >
                          Seller receives
                        </Typography>

                        {get(response, 'remittance') ? (
                          <div className="my-[0.344rem]">
                            <Typography variant="heading24" color="color-blue-180" bold>
                              {get(response, 'remittance')}
                            </Typography>
                          </div>
                        ) : (
                          <SvgIcon
                            className="transform rotate-90"
                            name="more_vert"
                            height="2rem"
                            width="2rem"
                            fill="#D9D9D9"
                          />
                        )}
                        <div className="flex gap-[0.12rem]">
                          <Typography
                            variant="paragraph10"
                            color={
                              get(response, 'remittance')
                                ? 'color-black-100'
                                : 'color-gray-110'
                            }
                          >
                            {get(metaContext, 'meta.calculator.spiffMode') !== 'none'
                              ? 'total payout'
                              : `after ${vendorFee} fee`}
                          </Typography>
                          {hasFee &&
                            renderFeeTooltip(response, documentationFee, discountTooltip)}
                        </div>
                        {renderSpiffAmount && (
                          <div className="py-1.5 px-3 bg-vartana-steel-30 rounded-[2rem] mt-2 flex gap-0.5">
                            <Typography
                              variant="paragraph10"
                              bold
                              color="color-blue-180"
                              className="ml-0.5"
                            >
                              {get(response, 'spiff_amount', '')}
                            </Typography>
                            <Typography variant="paragraph10" bold color="color-blue-180">
                              SPIFF
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default InstallmentTab;
