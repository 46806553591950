import { Button } from '@vartanainc/design-system';
import { ReactElement, useState, useContext } from 'react';
import { useReactiveVar } from '@apollo/client';
import { get } from 'lodash';
import { HasPermission } from '../../../../components/HasPermission/HasPermission';
import { sessionVar } from '../../../../graphql/cache';
import { DownloadButton } from '../../../../components/Button/DownloadButton';
import { PROGEN_CTA, downloadStatuses } from '../../../../constants/common.constants';
import '../../../CommonWidgetV2/CommonWidget.scss';
import { ACTION, RESOURCE } from '../../../../constants/permissions';
import usePermissions from '../../../../utils/hooks/permissions';
import { WidgetContext } from '../../../../context/WidgetContext';
import VendorSignatureRequired from '../../../Orders/Summary/VendorSignatureRequired/VendorSignatureRequired';

interface ProgenFooterProps {
  actions: {
    label: string;
    type: string;
    isEnabled: boolean;
    slug: string;
  }[];
  handleClick(slug: string): void;
  disableGenerateProposal?: boolean;
  downloadProposalStatus?: string;
  isOrderFooter?: boolean;
  createOrderDisabled?: boolean;
  calculatorHasErrors?: boolean;
}

const ProgenFooter = ({
  actions,
  handleClick,
  disableGenerateProposal,
  downloadProposalStatus,
  isOrderFooter,
  createOrderDisabled = false,
  calculatorHasErrors,
}: ProgenFooterProps): JSX.Element => {
  const sessionData = useReactiveVar(sessionVar);
  const [disabledButtons, setDisabledButtons] = useState<string[]>([]);
  const [showVendorSignature, setShowVendorSignature] = useState(true);
  const widgetContext = useContext(WidgetContext);
  const [, hasPermission] = usePermissions();

  const selectedCustomer = get(widgetContext, 'selectedCompany', {
    buyerRelationToVendor: '',
    seller: null,
  });

  const isDownloadButtonEnabled =
    !disableGenerateProposal &&
    !calculatorHasErrors &&
    downloadProposalStatus === downloadStatuses.DEFAULT;

  const handleButtonClick = (slug: string): void => {
    // these buttons should get disabled after clicking
    const endpointButtons = [
      PROGEN_CTA.generateNewTerms,
      PROGEN_CTA.updateRates,
      PROGEN_CTA.customerCheckout,
      PROGEN_CTA.getStarted,
      PROGEN_CTA.submitForm,
    ];
    if (endpointButtons.includes(slug)) setDisabledButtons([...disabledButtons, slug]);
    handleClick(slug);
  };

  const renderButtons = (
    label: string,
    type: string,
    isEnabled: boolean,
    slug: string
  ): ReactElement => {
    if (
      slug === PROGEN_CTA.createOrder &&
      !hasPermission('order', 'create', selectedCustomer)
    )
      return <></>;
    if (
      slug === PROGEN_CTA.approveOrder &&
      !hasPermission('order', 'approve', selectedCustomer)
    )
      return <></>;
    if (
      slug === PROGEN_CTA.reviewAndSign &&
      !hasPermission('order', 'counter_sign_document', selectedCustomer)
    )
      return <></>;
    if (
      slug === PROGEN_CTA.provideInformation &&
      !hasPermission('payment', 'upload_funding_invoice', selectedCustomer)
    )
      return <></>;
    if (
      slug === PROGEN_CTA.reviewAndApprove &&
      !hasPermission('order', 'approve_purchase_order', selectedCustomer)
    )
      return <></>;
    if (
      slug === PROGEN_CTA.downloadStatement &&
      !hasPermission('order', 'download_payoff_statement', selectedCustomer)
    )
      return <></>;
    if (
      slug === PROGEN_CTA.downloadStatement &&
      !hasPermission('order', 'download_payoff_statement', selectedCustomer)
    )
      return <></>;
    if (
      (slug === PROGEN_CTA.updateRates || slug === PROGEN_CTA.generateNewTerms) &&
      !hasPermission(RESOURCE.customer, ACTION.refresh_pricing, selectedCustomer)
    )
      return <></>;
    if (
      (slug === PROGEN_CTA.resendRequest ||
        slug === PROGEN_CTA.requestFromCustomer ||
        slug === PROGEN_CTA.uploadInfo) &&
      !hasPermission(RESOURCE.customer, ACTION.update, selectedCustomer)
    )
      return <></>;

    const renderLabel = (): string => {
      // TODO: Hamzasiddiqui3, Nuyaan95 -- Move this logic to BE and remove this
      const signatureCtaLabel = 'Collect signature';
      const fallBackLabel = 'Get started';
      if (slug === PROGEN_CTA.getStarted && label === signatureCtaLabel) {
        return fallBackLabel;
      }
      return label;
    };

    switch (slug) {
      case PROGEN_CTA.downloadProposal:
        return (
          <HasPermission
            resource="order"
            action="download_proposal"
            customer={selectedCustomer}
          >
            <DownloadButton
              isDisabled={!isDownloadButtonEnabled}
              btnClassName={`${
                isDownloadButtonEnabled
                  ? 'bg-white border-vartana-blue-120 border-[1.5px] br-2 hover:bg-[#e4edff] active:bg-[#21438a33]'
                  : 'bg-vartana-gray-40-v3'
              } py-2.5 w-40 rounded h-[2.375rem]`}
              textClassName={`${
                isDownloadButtonEnabled ? 'text-vartana-blue-120' : 'text-vartana-gray-50'
              } vartana-p-small-bold`}
              btnText="Download proposal"
              onClick={() => handleClick(slug)}
              downloadingStatus={downloadProposalStatus}
            />
          </HasPermission>
        );
      case PROGEN_CTA.inviteReseller:
        return get(sessionData, 'session.user.company.useResellerWorkflow', false) ? (
          <HasPermission resource="reseller" action="invite" customer={selectedCustomer}>
            <Button
              onClick={() => handleClick(slug)}
              size="x-small"
              disabled={!isEnabled}
              backgroundColor={type === 'primary' ? 'primary' : 'secondary'}
              variant={type === 'secondary' ? 'outlined' : 'contained'}
              id="progen-cta"
            >
              {label}
            </Button>
          </HasPermission>
        ) : (
          <></>
        );
      case PROGEN_CTA.reviewAndSign:
        return showVendorSignature ? (
          <VendorSignatureRequired
            orderNo={get(widgetContext, 'selectedOrder.number', '')}
            testMode={get(widgetContext, 'selectedCompany.seller.testMode', false)}
            updatedAt={get(widgetContext, 'selectedOrder.updatedAt', '')}
            onSigned={() => setShowVendorSignature(false)}
            isTrack
          />
        ) : (
          <></>
        );
      default:
        return (
          <Button
            onClick={() => handleButtonClick(slug)}
            key={label}
            size="x-small"
            disabled={
              !isEnabled || (slug === PROGEN_CTA.createOrder && createOrderDisabled)
            }
            backgroundColor={type === 'primary' ? 'primary' : 'secondary'}
            variant={type === 'secondary' ? 'outlined' : 'contained'}
            id="progen-cta"
            className={`cursor-pointer ${
              disabledButtons.includes(slug) ? 'opacity-70 pointer-events-none' : ''
            } ${type === 'secondary' ? 'active:bg-[#21438a33]' : ''}`}
          >
            {renderLabel()}
          </Button>
        );
    }
  };

  return (
    <div
      className={`progen-footer flex flex-row justify-center text-center items-center w-full rounded-b-lg gap-2 ${
        isOrderFooter ? 'order-footer pt-3' : ''
      }`}
    >
      {actions?.map(({ label, type, isEnabled, slug }) => (
        <div key={slug}>{renderButtons(label, type, isEnabled, slug)}</div>
      ))}
    </div>
  );
};

export default ProgenFooter;
