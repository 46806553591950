import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { useMutation } from '@apollo/client';
import { omit, get } from 'lodash';
import { Button, Card } from '@vartanainc/design-system';

import { SEND_APPRAISAL_APPLICATION } from '../../../graphql/queries/customer';

import FormInput from '../../../components/FormInput';
// import Button from '../../../components/Button';
import { showErrorToast, showToast } from '../../../utils/helpers';
import {
  commonRegex,
  FIELD_INVALID_MESSAGES,
  requiredPhoneFieldValidation,
} from '../../../constants/common.constants';
import TextInputField from '../../../designSystem/TextInput/TextInputField';
import PatternInput from '../../../designSystem/PatternInput/PatternInput';
import TextAreaInput from '../../../designSystem/TextAreaInput/TextAreaInput';

const SendCheckoutLinkFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.firstName),
  lastName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.lastName),
  phone: requiredPhoneFieldValidation,
  email: yup
    .string()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('This field is required'),
  message: yup.string(),
});

function SendCheckoutLinkForm({ appraisalNumber, disabled, formFocusSwitch }) {
  const [initialFocusFlag, setInitialFocusFlag] = useState(false);
  const [sendApplication] = useMutation(SEND_APPRAISAL_APPLICATION, {
    refetchQueries: ['initProgen'],
  });
  const firstNameRef = useRef();

  useEffect(() => {
    if (initialFocusFlag) firstNameRef.current.focus();
    else setInitialFocusFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFocusSwitch]);

  return (
    <Card
      tabindex={0}
      variant="variable"
      parentContainerClassName="h-fit checkout-link-form-card"
      title="List customer details to forward information request"
      content={(
        <Formik
          initialValues={{
            validateOnMount: true,
            companyNumber: appraisalNumber,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
          }}
          validationSchema={SendCheckoutLinkFormSchema}
          onSubmit={async (values, { setErrors }) => {
            try {
              const responseData = await sendApplication({
                variables: {
                  ...omit(values, ['validateOnMount']),
                },
              });

              const errors = get(responseData, 'errors', {});

              if (Object.keys(errors).length) {
                setErrors(errors);
              } else {
                showToast(
                  'success',
                  'Request sent successfully! Customer will receive document upload link shortly.'
                );
              }
            } catch (e) {
              showErrorToast();
            }
          }}
        >
          {({ isSubmitting, isValid, dirty }) => {
            const submitDisabled = disabled || isSubmitting || !(isValid && dirty);

            return (
              <Form>
                <fieldset disabled={disabled}>
                  <FormInput className="tw-hidden" name="companyNumber" type="hidden" />
                  <div className="space-y-4">
                    <div className="flex flex-row space-x-4">
                      <TextInputField
                        name="firstName"
                        label="Legal first name"
                        disabled={disabled}
                        ref={firstNameRef}
                      />

                      <TextInputField
                        name="lastName"
                        label="Legal last name"
                        disabled={disabled}
                      />
                    </div>

                    <div className="flex flex-row space-x-4">
                      <TextInputField
                        name="email"
                        label="Signer's email"
                        disabled={disabled}
                      />

                      <PatternInput
                        name="phone"
                        label="Phone"
                        disableFullStoryRecording
                        mask="_"
                        disabled={disabled}
                      />
                    </div>

                    <div className="flex flex-row">
                      <TextAreaInput
                        name="message"
                        label="Include a message"
                        placeholder="Hello..."
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row justify-center mt-6">
                    <Button size="x-large" disabled={submitDisabled}>
                      Send
                    </Button>
                  </div>
                </fieldset>
              </Form>
            );
          }}
        </Formik>
      )}
    >
    </Card>
  );
}

SendCheckoutLinkForm.propTypes = {
  appraisalNumber: PropTypes.string,
  disabled: PropTypes.bool,
  formFocusSwitch: PropTypes.bool,
};

SendCheckoutLinkForm.defaultProps = {
  appraisalNumber: '',
  disabled: false,
  formFocusSwitch: false,
};

export default SendCheckoutLinkForm;
