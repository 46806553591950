import { useNavigate } from 'react-router-dom';
import { ButtonV2, Typography } from '@vartanainc/design-system';
import usePermissions from '../../utils/hooks/permissions';
import { HasPermission } from '../HasPermission/HasPermission';
import { ReactComponent as IllustrationEmptyBoxIcon } from '../../assets/illustration-empty-box.svg';

interface ZeroStateProps {
  page:
    | 'customers'
    | 'orders'
    | 'analytics'
    | 'payments'
    | 'vendors'
    | 'customer_contact'
    | 'customer_order'
    | 'customer_payment'
    | 'customer_invoices'
    | 'resellers';
  cta: 'add_customer' | 'add_order' | 'payments' | 'none' | 'invite_reseller';
}

export const getCtaText = (customerExists: boolean, orderExists: boolean): string => {
  if (!customerExists) return 'add_customer';
  if (!orderExists) return 'add_order';
  return 'payments';
};

const ZeroState = ({ page, cta }: ZeroStateProps): JSX.Element => {
  const navigate = useNavigate();

  const [, userHasPermission] = usePermissions();
  const canCreateCustomer = userHasPermission('customer', 'create');
  const canCreateOrder = userHasPermission('order', 'create');

  const getHeadingText = (): string => {
    switch (page) {
      case 'customers':
        return 'Manage your customers';
      case 'orders':
        return 'View your orders in one place';
      case 'analytics':
        return 'Get insights from your orders';
      case 'payments':
        return 'Keep track of your payouts';
      case 'vendors':
        return 'View your orders in one place';
      case 'customer_contact':
        return 'Manage customer contacts';
      case 'customer_order':
        return 'Monitor customer orders';
      case 'customer_payment':
      case 'customer_invoices':
        return 'Monitor customer invoices';
      case 'resellers':
        return 'Manage your partners';
      default:
        return '';
    }
  };

  const getCopyText = (): string => {
    switch (cta) {
      case 'add_customer':
        return canCreateCustomer
          ? 'Add a customer to get started.'
          : 'Customer data will show up once a customer is added.';
      case 'add_order':
        switch (canCreateOrder) {
          case true:
            switch (page) {
              case 'customer_contact':
                return 'Data will show up once a contact is added.';
              case 'customer_order':
                return 'Data will show up once an order is created.';
              case 'customer_payment':
              case 'customer_invoices':
                return 'Data will show up once an order is financed.';
              default:
                return 'Create an order to get started.';
            }
          default:
            return 'Data will show up once there is an order.';
        }
      case 'payments':
        return canCreateOrder
          ? 'Complete an order to view payments.'
          : 'Data will show up once there is a completed order.';
      case 'invite_reseller':
        return 'Invite a partner to get started.';
      case 'none':
        switch (page) {
          case 'vendors':
            return 'Vendor data will show up once they add you to their order.';
          case 'customer_contact':
            return 'Data will show up once a contact is added.';
          case 'customer_order':
            return 'Data will show up once an order is created.';
          case 'customer_payment':
          case 'customer_invoices':
            return 'Data will show up once an order is financed.';
          default:
            return '';
        }
      default:
        return '';
    }
  };

  const getButton = (): JSX.Element => {
    switch (cta) {
      case 'add_customer':
        return (
          <HasPermission resource="customer" action="create">
            <ButtonV2
              variant={{ type: 'primary', typography: 'paragraph14' }}
              text="Add customer"
              onClick={() => navigate('/dashboard/customers/new')}
            />
          </HasPermission>
        );
      case 'add_order':
        return (
          <HasPermission resource="order" action="create">
            <ButtonV2
              variant={{ type: 'primary', typography: 'paragraph14' }}
              text="Add order"
              onClick={() => navigate('/dashboard/orders/new')}
            />
          </HasPermission>
        );
      case 'payments':
        return (
          <HasPermission resource="order" action="view">
            <ButtonV2
              variant={{ type: 'primary', typography: 'paragraph14' }}
              text="View orders"
              onClick={() => navigate('/dashboard/orders')}
            />
          </HasPermission>
        );
      case 'none':
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="flex flex-1 justify-center w-full items-center bg-vartana-steel-20">
        <div className="flex flex-col items-center gap-6">
          <div>
            <IllustrationEmptyBoxIcon className="ml-auto w-auto h-[13.625rem]" />
          </div>

          <div className="flex flex-col items-center gap-2">
            <Typography variant="heading20" color="color-black-100">
              {getHeadingText()}
            </Typography>
            <Typography variant="heading16" color="color-steel-180">
              {getCopyText()}
            </Typography>
          </div>

          {getButton()}
        </div>
      </div>
    </>
  );
};

export default ZeroState;
