import { Typography } from '@vartanainc/design-system';
import { ReactElement, useContext } from 'react';
import { get } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { WidgetMetaContext } from '../../../../context/WidgetMetaContext/WidgetMetaContext';
import { renderPgStatus } from '../widgetUtils';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import { CurrencyPill } from '../../../../components/Pill/CurrencyPill';
import { sessionVar } from '../../../../graphql/cache';

const CreditInformation = (): ReactElement => {
  const metaContext = useContext(WidgetMetaContext);
  const activeTab: string = get(metaContext, 'activeTab', '-');

  const sessionData = useReactiveVar(sessionVar);
  const showCurrency = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled',
    false
  );
  const currency = get(metaContext, 'meta.calculator.currency', '');

  return (
    <div className="flex flex-1 flex-col justify-center items-center self-stretch py-1.5 px-0 gap-4">
      <div className="flex flex-col justify-end items-center gap-2 pr-0">
        <div className="flex gap-2 justify-center items-center">
          <Typography variant="paragraph14" bold>
            Available amount
          </Typography>
          {showCurrency && <CurrencyPill compactPill currency={currency} />}
        </div>
        <Typography variant="heading24" color="color-blue-180">
          {get(metaContext, `${activeTab}.creditInfo.availableCredit`, '')}
        </Typography>
      </div>
      <div className="flex flex-col justify-center items-start gap-2 rounded-lg border border-[#C4C4C4] p-4 min-w-[15.625rem]">
        {Object.keys(get(metaContext, `${activeTab}.creditInfo`, {})).map(
          (key) =>
            key !== 'availableCredit' && (
              <div className="flex flex-row items-center justify-center gap-2" key={key}>
                <SvgIcon
                  name="check"
                  width="1rem"
                  height="1rem"
                  fill="#21438B"
                  className="justify-center"
                />
                <Typography variant="paragraph14">
                  {get(metaContext, `${activeTab}.creditInfo.${key}`, '')}
                </Typography>
              </div>
            )
        )}
      </div>
      <div className="flex flex-col items-center gap-[0.125rem]">
        <Typography variant="paragraph10" color="color-black-100">
          {get(metaContext, 'meta.creditDetails.minimumAmount', '')}
        </Typography>
        <Typography variant="paragraph10" color="color-black-100">
          {get(metaContext, 'meta.creditDetails.appraisalValidUntil', '')} •{' '}
          {get(metaContext, 'meta.creditDetails.pricingValidUntil', '')}
        </Typography>
        {renderPgStatus(get(metaContext, 'meta.calculator.pgRequired', false), '')}
      </div>
    </div>
  );
};

export default CreditInformation;
